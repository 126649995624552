<img
  [title]="logo.logoTitle"
  [alt]="logo.logoAlt"
  src="./assets/images/{{logo.logoImg}}"/>
<nav>
    <a *ngFor="let menuItem of menuItems"
       [routerLink]="menuItem.websiteRoute"
       routerLinkActive="active"
       class="tab">
      {{ menuItem.label }}
    </a>
</nav>
<ng-container *wvHasGeslotenDeel>
  <ng-container *ngIf="isUserAuthenticated() else notLoggedIn">
    <ng-container *ngIf="(user$ | async) as user else notLoggedIn">
      <button mat-button [matMenuTriggerFor]="uitlogMenu" class="yellow-btn">
        Welkom, {{user.voornaam}}
        <fa-icon icon="caret-down"></fa-icon>
      </button>
      <mat-menu #uitlogMenu="matMenu" class="header-menu">
        <button mat-menu-item (click)="goToGeslotenPlatform()">
          <fa-icon icon="chart-line-down"></fa-icon>
          Dashboard
        </button>
        <button mat-menu-item (click)="logout()">
          <fa-icon icon="sign-out-alt"></fa-icon>
          Uitloggen
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>
  <ng-template #notLoggedIn>
    <span>
      <button class="yellow-btn login-register-btn" (click)="goToRegistreren()">Registreer</button>
      <button class="yellow-btn login-register-btn" (click)="goToLogin()">Login</button>
    </span>
  </ng-template>
</ng-container>
