import {Injectable} from '@angular/core';
import {MatDialog as MatDialog} from '@angular/material/dialog';
import {CookieService as NgxCookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(private dialog: MatDialog,
              private ngxCookieService: NgxCookieService) {
  }

  isCookieAccepted(cookieType: CookieType): boolean {
    return this.ngxCookieService.get(cookieType) === 'true';
  }

  setCookie(cookieType: CookieType, value: boolean): void {
    this.ngxCookieService.set(cookieType, value.toString(), 365);
  }

  acceptAllCookies(): void {
    Object.values(CookieType).forEach(cookieType => {
      this.setCookie(cookieType, true);
    });
  }

  checkCookieExist(cookieType: CookieType): boolean {
    return this.ngxCookieService.check(cookieType);
  }

}

export enum CookieType {
  NECESSARY = 'necessary-cookies',
  STATISTIC = 'statistic-cookies',
  THIRD_PARTY = 'third-party-cookies',
  MARKETING = 'marketing-cookies'
}
