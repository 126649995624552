import {Injectable} from '@angular/core';
import {MatDialog as MatDialog, MatDialogConfig as MatDialogConfig} from '@angular/material/dialog';
import {CookieDialogComponent} from '../components/cookie-dialog/cookie-dialog.component';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CookieService, CookieType} from './cookie.service';
import {FacebookPixelService} from './facebook-pixel.service';
import {LinkedinPixelService} from './linkedin-pixel.service';
import {MatomoAnalyticsService} from './matomo-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class CookieDialogService {

  constructor(private dialog: MatDialog,
              private cookieService: CookieService,
              private matomoAnalyticsService: MatomoAnalyticsService,
              private fbPixelService: FacebookPixelService,
              private linkedInPixelService: LinkedinPixelService) {
  }

  initializeCookies(): void {
    if (!this.validateAllCookiesAreSet()) {
      this.showCookieDialog().subscribe();
    } else {
      this.handleCookieSettings();
    }
  }

  showCookieDialog(): Observable<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '90vw';
    dialogConfig.maxWidth = '800px';
    dialogConfig.position = {bottom: `5vh`};
    return this.dialog.open(CookieDialogComponent, dialogConfig).afterClosed().pipe(
      tap(() => this.handleCookieSettings()),
      tap(() => location.reload()));
  }

  isThirdPartyCookieAccepted(): boolean {
    return this.cookieService.isCookieAccepted(CookieType.THIRD_PARTY);
  }

  private handleCookieSettings(): void {
    if (this.cookieService.isCookieAccepted(CookieType.STATISTIC)) {
      this.matomoAnalyticsService.initializeTracking();
    } else {
      this.matomoAnalyticsService.removeTracking();
    }
    if (this.cookieService.isCookieAccepted(CookieType.MARKETING)) {
      this.fbPixelService.initializeTracking();
      this.linkedInPixelService.initializeTracking();
    } else {
      this.fbPixelService.removeTracking();
      this.linkedInPixelService.removeTracking();
    }
  }

  private validateAllCookiesAreSet(): boolean{
    return this.cookieService.checkCookieExist(CookieType.NECESSARY)
      && this.cookieService.checkCookieExist(CookieType.STATISTIC)
      && this.cookieService.checkCookieExist(CookieType.THIRD_PARTY)
      && this.cookieService.checkCookieExist(CookieType.MARKETING);
  }

}

