<carousel
  [autoPlay]="true"
  [animationTimingMs]="250"
  animationTimingFn="ease-in"
  [maxWidthCarousel]="768"
  [autoSlide]="true"
  [infinite]="true"
>
  <div *ngFor="let slide of afbeeldingUrls; let i = index" class="carousel-slide">
    <img [ngSrc]="slide" fill alt="">
  </div>
</carousel>
