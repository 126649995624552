import {Injectable} from '@angular/core';
import {MatSnackBar as MatSnackBar} from '@angular/material/snack-bar';
import {NotificationComponent, NotificationType} from './notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) {
  }

  showNotificationBar(message: string, notificationType: NotificationType, duration: number = 3) {
    this.snackBar.openFromComponent(NotificationComponent, {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: duration * 20000,
      data: {
        message,
        notificationType
      },
      panelClass: ['transparent-snackbar']
    });
  }
}
