import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DoubleBorderButtonComponent} from './components/double-border-button/double-border-button.component';
import {ButtonComponent} from './components/button/button.component';
import {GradientButtonComponent} from './components/gradient-button/gradient-button.component';
import {YoutubePlayerComponent} from './components/youtube-player/youtube-player.component';
import {SafePipe} from './pipes/safe.pipe';
import {MarkdownModule} from 'ngx-markdown';
import {DoubleBorderHeaderComponent} from './components/double-border-header/double-border-header.component';
import {BackgroundStripeComponent} from './components/background-stripe/background-stripe.component';
import {CheckboxComponent} from './form/checkbox/checkbox.component';
import {TextInputComponent} from './form/text-input/text-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CircularImageLinkComponent} from './components/circular-image-link/circular-image-link.component';
import {AppCarouselComponent} from './components/carousel/carousel.component';
import {DynamicContentComponent} from './components/dynamic-content/dynamic-content.component';
import {QuoteComponent} from './components/quote/quote.component';
import {ImageComponent} from './components/image/image.component';
import {NumberInputComponent} from './form/number-input/number-input.component';
import {ContactAndNieuwsbriefComponent} from './components/contact-and-nieuwsbrief/contact-and-nieuwsbrief.component';
import {ContactComponent} from './components/contact/components/contact/contact.component';
import {NieuwsbriefComponent} from './components/nieuwsbrief/components/nieuwsbrief/nieuwsbrief.component';
import {NieuwsbriefFormComponent} from './components/nieuwsbrief/components/nieuwsbrief-form/nieuwsbrief-form.component';
import {HorizontalScrollListComponent} from './components/horizontal-scroll-list/horizontal-scroll-list.component';
import {HoeOndersteunenComponent} from './components/hoe-ondersteunen/hoe-ondersteunen.component';
import {ConfirmDialogComponent} from './dialog/confirm-dialog/confirm-dialog.component';
import {RegistrationInformationDialogComponent} from './dialog/registration-information-dialog/registration-information-dialog.component';
import {FaConfig, FaIconLibrary, FontAwesomeModule,} from '@fortawesome/angular-fontawesome';
import {
  faAnchor,
  faAt,
  faAward,
  faBallot,
  faBookHeart,
  faCalendar,
  faCalendarAlt,
  faCaretDown,
  faChartLineDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faComment,
  faComments,
  faCompass,
  faDownload,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faEuroSign,
  faExclamation,
  faExclamationTriangle,
  faEye,
  faFingerprint,
  faGrinBeam,
  faHandHeart,
  faHandHoldingSeedling,
  faHandsHelping,
  faHeart as fasHeart,
  faHistory,
  faHouseDay,
  faIcons,
  faInfo,
  faLink,
  faMapMarkerAlt,
  faMapMarkerSmile,
  faMedal,
  faPaperPlane,
  faPen,
  faPeopleCarry,
  faPhone,
  faPlus,
  faPrint,
  faRunning,
  faSave,
  faSignOutAlt,
  faSpinner,
  faStamp,
  faSync,
  faTag,
  faTimes,
  faTrash,
  faUser,
  faUsers,
  faUsersClass,
  faUsersCog,
} from '@fortawesome/pro-solid-svg-icons';
import {faFacebookSquare, faLinkedin, faTwitter,} from '@fortawesome/free-brands-svg-icons';
import {NotificationComponent} from './notification/notification.component';
import {ZinActieComponent} from './components/zinanalyse/zin-actie/zin-actie.component';
import {SpinnerDialogComponent} from './dialog/spinner-dialog/spinner-dialog.component';
import {ScrollToDirective} from './directives/scroll-to.directive';
import {faHeart as farHeart} from '@fortawesome/pro-regular-svg-icons';
import {CapitalizeFirstLetterPipe} from './pipes/capitalize-first-letter.pipe';
import {HasGeslotenDeelDirective} from './directives/has-gesloten-deel.directive';
import {RequiresOneOfRolesDirective} from './directives/requires-one-of-roles.directive';
import {IsWarmeStedenDirective} from './directives/is-warme-steden.directive';
import {IsWarmeScholenDirective} from './directives/is-warme-scholen.directive';
import {TagCardViewComponent} from './tag/tag-card-view/tag-card-view.component';
import {TagsFilterComponent} from './tag/tags-filter/tags-filter.component';
import {MarkdownComponent} from './components/markdown/markdown.component';
import {PaginatorComponent} from './pagination/paginator/paginator.component';
import {BlueBoxComponent} from './components/blue-box/blue-box.component';
import {FilterTagComponent} from './components/filter-tag/filter-tag.component';
import {PrintButtonComponent} from './components/print-button/print-button.component';
import {NgxPrintModule} from 'ngx-print';
import {CarouselComponent} from 'ngx-carousel-ease';
import {MatDialogActions, MatDialogContent} from '@angular/material/dialog';

@NgModule({
  declarations: [
    DoubleBorderButtonComponent,
    ButtonComponent,
    GradientButtonComponent,
    YoutubePlayerComponent,
    DoubleBorderHeaderComponent,
    BackgroundStripeComponent,
    CheckboxComponent,
    TextInputComponent,
    CircularImageLinkComponent,
    AppCarouselComponent,
    DynamicContentComponent,
    QuoteComponent,
    ImageComponent,
    NumberInputComponent,
    ContactAndNieuwsbriefComponent,
    ContactComponent,
    NieuwsbriefComponent,
    NieuwsbriefFormComponent,
    HorizontalScrollListComponent,
    HoeOndersteunenComponent,
    ConfirmDialogComponent,
    RegistrationInformationDialogComponent,
    NotificationComponent,
    ZinActieComponent,
    SpinnerDialogComponent,
    ScrollToDirective,
    HasGeslotenDeelDirective,
    RequiresOneOfRolesDirective,
    IsWarmeStedenDirective,
    IsWarmeScholenDirective,
    CapitalizeFirstLetterPipe,
    SafePipe,
    TagCardViewComponent,
    TagsFilterComponent,
    MarkdownComponent,
    PaginatorComponent,
    BlueBoxComponent,
    FilterTagComponent,
    PrintButtonComponent,
  ],
  imports: [
    CommonModule,
    MarkdownModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    NgxPrintModule,
    CarouselComponent,
    NgOptimizedImage,
    MatDialogContent,
    MatDialogActions
  ],
  exports: [
    DoubleBorderButtonComponent,
    ButtonComponent,
    GradientButtonComponent,
    YoutubePlayerComponent,
    DoubleBorderHeaderComponent,
    BackgroundStripeComponent,
    MarkdownComponent,
    CheckboxComponent,
    TextInputComponent,
    CircularImageLinkComponent,
    DynamicContentComponent,
    ImageComponent,
    NumberInputComponent,
    ContactAndNieuwsbriefComponent,
    ContactComponent,
    NieuwsbriefComponent,
    NieuwsbriefFormComponent,
    HorizontalScrollListComponent,
    HoeOndersteunenComponent,
    ConfirmDialogComponent,
    RegistrationInformationDialogComponent,
    FontAwesomeModule,
    ZinActieComponent,
    ScrollToDirective,
    CapitalizeFirstLetterPipe,
    CarouselComponent,
    ScrollToDirective,
    HasGeslotenDeelDirective,
    RequiresOneOfRolesDirective,
    IsWarmeStedenDirective,
    IsWarmeScholenDirective,
    TagCardViewComponent,
    TagsFilterComponent,
    MarkdownComponent,
    PaginatorComponent,
    BlueBoxComponent,
    FilterTagComponent,
    PrintButtonComponent,
    AppCarouselComponent,
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    faConfig.fixedWidth = true;
    library.addIcons(
      fasHeart,
      farHeart,
      faLink,
      faFacebookSquare,
      faLinkedin,
      faTwitter,
      faTimes,
      faAt,
      faCaretDown,
      faSignOutAlt,
      faChartLineDown,
      faComment,
      faCalendar,
      faMapMarkerAlt,
      faUsers,
      faEuroSign,
      faExclamationTriangle,
      faInfo,
      faCalendarAlt,
      faPen,
      faPhone,
      faPaperPlane,
      faEnvelope,
      faChevronLeft,
      faChevronRight,
      faChevronDown,
      faChevronUp,
      faEye,
      faFingerprint,
      faUser,
      faUsersClass,
      faUsersCog,
      faBookHeart,
      faStamp,
      faAward,
      faHandHoldingSeedling,
      faGrinBeam,
      faHandsHelping,
      faHistory,
      faComments,
      faEdit,
      faMedal,
      faEllipsisV,
      faPlus,
      faSync,
      faSpinner,
      faTag,
      faSave,
      faTrash,
      faPeopleCarry,
      faBallot,
      faCompass,
      faAnchor,
      faChild,
      faHouseDay,
      faIcons,
      faMapMarkerSmile,
      faRunning,
      faHandHeart,
      faExclamation,
      faDownload,
      faPrint
    );
  }
}
