import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {AuthService} from '../../../auth/services/auth.service';
import {Observable} from 'rxjs';
import {User} from '../../../auth/models/user';
import {MatTabNavPanel} from '@angular/material/tabs';

@Component({
  selector: 'wv-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent implements OnInit {

  logo = environment.websiteConfiguratie.logo;
  menuItems = environment.websiteConfiguratie.menu;

  user$: Observable<User>;

  constructor(
    private router: Router,
    private authService: AuthService,
    ) {

  }

  ngOnInit(): void {
    this.user$ = this.authService.getLoggedInUser$();
  }

  goToLogin() {
    this.authService.login('/platform');
  }

  logout() {
    this.authService.logout();
  }

  goToGeslotenPlatform() {
    this.router.navigate(['/platform']);
  }

  goToRegistreren() {
    this.router.navigate(['/auth/registreer']);
  }

  isUserAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }
}
