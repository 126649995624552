import {environment} from '../../environments/environment';
import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig = {
  region: environment.auth.region,
  userPoolId: environment.auth.userPoolId,
  userPoolWebClientId: environment.auth.userPoolWebClientId,
  cognitoDomain: environment.auth.cognitoDomain,
};

export function getOAuthConfig(): AuthConfig {
  return {
    clientId: authConfig.userPoolWebClientId,
    issuer: `https://cognito-idp.eu-central-1.amazonaws.com/${authConfig.userPoolId}`,
    logoutUrl: `${authConfig.cognitoDomain}/logout`,
    redirectUri: window.location.origin + '/auth/callback',
    responseType: 'code',
    scope: 'email openid profile',
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: false,
  };
}
