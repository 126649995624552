import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './routing/app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from './layout/layout.module';
import {SecurityModule} from './security/security.module';
import {CookieModule} from './cookie/cookie.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.interceptor';
import {MatomoModule, MatomoRouterModule, withRouter} from 'ngx-matomo-client';
import {SharedModule} from './shared/shared.module';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import { provideMatomo } from 'ngx-matomo-client';
import {MatomoRouter} from 'ngx-matomo-client/router/matomo-router.service';
import {MatTabNavPanel} from '@angular/material/tabs';

const APP_MODULES = [
  LayoutModule,
  SecurityModule,
  CookieModule,
  SharedModule
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...APP_MODULES,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    MatomoModule.forRoot({
      siteId: environment.matomoSiteId,
      trackerUrl: 'https://warmvlaanderen.matomo.cloud/',
      disabled: environment.preview || environment.matomoSiteId === '',
    }),
    MatomoRouterModule,
    MatTabNavPanel
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: OAuthStorage, useValue: sessionStorage},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
})
export class AppModule {
}
