import {WarmeScholenConfig} from '../app/website-configuration/warme-scholen-config';
import {Tenant} from '../app/website-configuration/tenant';

export const environment = {
  production: false,
  preview: false,
  tenant: Tenant.WARME_SCHOLEN,
  apiUrl: 'https://api.tst.warmescholen.net',
  cmsApiUrl: 'https://cms.tst.warmvlaanderen.net/api',
  cmsApiVoorvoegsel: 'ws',
  applicationTitle: 'Warme Scholen',
  websiteConfiguratie: WarmeScholenConfig,
  matomoSiteId: '3',
  forumUrl: 'https://forum.tst.warmescholen.net',
  auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_gY7GlDG9n',
    userPoolWebClientId: '77k1381rdrimqlc3urokcmnuvk',
    cognitoDomain: 'https://warm-vlaanderen-tst-ws.auth.eu-central-1.amazoncognito.com'
  },
};
