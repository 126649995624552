import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {CookieService as NgxCookieService} from 'ngx-cookie-service';

declare const fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  private fbxScript: HTMLScriptElement;

  constructor(private router: Router,
              private ngxCookieService: NgxCookieService) {
  }

  initializeTracking(): void {
    this.addFPScript();
    this.trackPageView();
  }

  removeTracking(): void {
    this.fbxScript?.parentElement.removeChild(this.fbxScript);
    this.ngxCookieService.deleteAll( '/', '.facebook.com');
  }

  private addFPScript(): void {
    this.fbxScript = document.createElement('script');
    this.fbxScript.async = true;
    this.fbxScript.innerHTML =
      `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');`;
    document.head.prepend(this.fbxScript);

    fbq('init', '159544786016333');
  }

  private trackPageView(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        fbq('track', 'PageView');
      });
  }

}
