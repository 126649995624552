import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {CookieService as NgxCookieService} from 'ngx-cookie-service';

declare const lintrk: Function;

@Injectable({
  providedIn: 'root'
})
export class LinkedinPixelService {

  private linkedinScript: HTMLScriptElement;

  constructor(private router: Router,
              private ngxCookieService: NgxCookieService) {
  }

  initializeTracking(): void {
    this.addLinkedinScript();
    this.trackPageView();
  }

  removeTracking(): void {
    this.linkedinScript?.parentElement.removeChild(this.linkedinScript);
    this.ngxCookieService.deleteAll( '/', '.linkedin.com');
    this.ngxCookieService.deleteAll( '/', '.ads.linkedin.com');
  }

  private addLinkedinScript(): void {
    this.linkedinScript = document.createElement('script');
    this.linkedinScript.innerHTML =
        `(function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);`;
    document.head.prepend(this.linkedinScript);

    lintrk();
  }

  private trackPageView(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        lintrk();
      });
  }

}
