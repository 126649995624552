import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog as MatDialog,
  MatDialogConfig as MatDialogConfig,
  MatDialogRef as MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { PrivacyEnCookieVerklaringUrl } from '../../../shared/models/privacy-verklaringen-model';
import { CookieSettingsDialogComponent } from '../cookie-settings-dialog/cookie-settings-dialog.component';
import { CookieService } from '../../services/cookie.service';

@Component({
  selector: 'wv-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss'],
})
export class CookieDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  privacyEnCookieVerklaringUrl$: Observable<PrivacyEnCookieVerklaringUrl>;

  constructor(
    private dialogRef: MatDialogRef<CookieDialogComponent>,
    private dialog: MatDialog,
    private cookieService: CookieService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.privacyEnCookieVerklaringUrl$ =
      this.sharedService.getPrivacyVerklaringenUrls();
  }

  acceptAllCookies() {
    this.cookieService.acceptAllCookies();
    this.dialogRef.close();
  }

  openCookieSettings() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '90vw';
    dialogConfig.maxWidth = '800px';
    dialogConfig.maxHeight = '90vh';
    dialogConfig.position = { bottom: `5vh` };

    this.subscriptions.add(
      this.dialog
        .open(CookieSettingsDialogComponent, dialogConfig)
        .afterClosed()
        .subscribe((accepted) => (accepted ? this.dialogRef.close() : null))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
