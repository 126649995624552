import {Component, Input, OnInit} from '@angular/core';
import {CarouselComponent} from 'ngx-carousel-ease';

@Component({
  selector: 'wv-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class AppCarouselComponent implements OnInit {

  @Input() afbeeldingUrls: Array<string>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
