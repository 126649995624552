import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  templateUrl: './spinner-dialog.component.html',
  styleUrls: ['./spinner-dialog.component.scss']
})
export class SpinnerDialogComponent {

  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.message = data.message;
  }

}
