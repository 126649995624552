import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SharedModule} from '../shared/shared.module';
import {HeaderMobileComponent} from './header/mobile/header-mobile.component';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule as MatListModule} from '@angular/material/list';
import {HeaderDesktopComponent} from './header/desktop/header-desktop.component';
import {MatButtonModule as MatButtonModule} from '@angular/material/button';
import {MatMenuModule as MatMenuModule} from '@angular/material/menu';
import {MatIconButton} from '@angular/material/button';
import {MatListItem, MatNavList} from '@angular/material/list';
import {MatTabLink, MatTabNav} from '@angular/material/tabs';
import {MatRipple} from '@angular/material/core';

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderDesktopComponent,
        HeaderMobileComponent,
        FooterComponent,
    ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    MatIconButton,
    MatNavList,
    MatListItem,
    MatTabNav,
    MatTabLink,
    MatRipple,
  ], exports: [
        HeaderComponent,
        FooterComponent
    ]
})
export class LayoutModule {
}
